var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Navbar', {
    attrs: {
      "nav-light": true
    }
  }), _c('section', {
    staticClass: "bg-half bg-primary d-table w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-lg-12 text-center"
  }, [_c('div', {
    staticClass: "page-next-level"
  }, [_c('h4', {
    staticClass: "title text-white"
  }, [_vm._v(" " + _vm._s(_vm.$t("profile.update")) + " - " + _vm._s(_vm.user.name) + " ")])])])])])]), _c('section', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('form', {
    staticClass: "login-form mt-4"
  }, [_c('div', {
    staticClass: "card bg-light border-0 rounded shadow",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center border-bottom"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.profile_picture ? _c('div', {
    staticClass: "col-md-12 text-center mt-4 mt-sm-0"
  }, [_c('img', {
    staticClass: "avatar avatar-large rounded-circle shadow d-block mx-auto",
    attrs: {
      "src": _vm.profile_picture,
      "alt": "",
      "height": "150"
    }
  }), _c('label', {
    staticClass: "btn btn-primary mt-2",
    attrs: {
      "for": "upload-photo"
    }
  }, [_vm._v(_vm._s(_vm.$t("change-dp")))]), _c('input', {
    staticClass: "form-control-file",
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  }), !_vm.profile_picture ? _c('div', {
    staticClass: "col-md-12 text-center mt-4 mt-sm-0"
  }, [_c('label', {
    staticClass: "upload-photo btn btn-primary",
    attrs: {
      "for": "upload-photo"
    }
  }, [_vm._v(_vm._s(_vm.$t("add-dp")))]), _c('input', {
    attrs: {
      "type": "file",
      "name": "photo",
      "id": "upload-photo",
      "accept": "image/*"
    },
    on: {
      "change": function ($event) {
        return _vm.filesChange($event.target.files);
      }
    }
  })]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.name.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("fullname")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('div', {
    staticClass: "input-group"
  }, [_c('div', {
    staticClass: "input-group-prepend"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.designation,
      expression: "form.designation"
    }],
    staticClass: "form-control",
    staticStyle: {
      "border-bottom-right-radius": "0",
      "border-top-right-radius": "0"
    },
    attrs: {
      "name": ""
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.form, "designation", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }, _vm.changeDesignation]
    }
  }, _vm._l(_vm.designations, function (designation) {
    return _c('option', {
      key: designation,
      domProps: {
        "value": designation
      }
    }, [_vm._v(" " + _vm._s(designation) + " ")]);
  }), 0)]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.name.$model,
      expression: "$v.form.name.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "id": "full_name",
      "required": ""
    },
    domProps: {
      "value": _vm.$v.form.name.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.name, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _vm.$v.form.name.$error && !_vm.$v.form.name.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.name")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mykad")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.ic_number,
      expression: "form.ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "ic_number",
      "required": "",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "ic_number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-8"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("old-ic")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.old_ic_number,
      expression: "form.old_ic_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "old_ic_number",
      "required": ""
    },
    domProps: {
      "value": _vm.form.old_ic_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "old_ic_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.gender.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("gender")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.gender.$model,
      expression: "$v.form.gender.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "name": "",
      "id": "gender"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.$v.form.gender, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v(" -- " + _vm._s(_vm.$t("choose.gender")) + " -- ")]), _vm._l(_vm.genders, function (gender, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": gender.split(':')[0]
      }
    }, [_vm._v(" " + _vm._s(gender.split(":")[1]) + " ")]);
  })], 2), _vm.$v.form.gender.$error && !_vm.$v.form.gender.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("gender")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.mobile_number.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("mobile-no")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.mobile_number.$model,
      expression: "$v.form.mobile_number.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "mobile_number",
      "required": ""
    },
    domProps: {
      "value": _vm.$v.form.mobile_number.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.mobile_number, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.mobile_number.$error && !_vm.$v.form.mobile_number.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.mobile-no")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("office-no")) + " ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.office_phone_number,
      expression: "form.office_phone_number"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "name": "",
      "required": ""
    },
    domProps: {
      "value": _vm.form.office_phone_number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "office_phone_number", $event.target.value);
      }
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.address.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("address")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('textarea', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.address.$model,
      expression: "$v.form.address.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "id": "address",
      "rows": "2"
    },
    domProps: {
      "value": _vm.$v.form.address.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.address, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.address.$error && !_vm.$v.form.address.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("address")) + " " + _vm._s(_vm.$t("validation.cannot-blank")) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.postcode.$error
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('span', {
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "11px",
      "color": "#333"
    },
    on: {
      "click": _vm.openpostcode
    }
  }, [_vm._v(_vm._s(_vm.$t("add-new")))])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.postcode.$model,
      expression: "$v.form.postcode.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "postcode"
    },
    domProps: {
      "value": _vm.$v.form.postcode.$model
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.postcode, "$model", $event.target.value.trim());
      }, _vm.getPostcode],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), _vm.$v.form.postcode.$error && !_vm.$v.form.postcode.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.postcode")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("city")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.city,
      expression: "form.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "city",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "city", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("state")))]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.form.state,
      expression: "form.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "state",
      "readonly": ""
    },
    domProps: {
      "value": _vm.form.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.form, "state", $event.target.value);
      }
    }
  })])])])])]), _c('div', {
    staticClass: "row align-items-center mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 col-md-3 text-md-left"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative",
    class: {
      'has-error': _vm.$v.form.email.$error
    }
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("email")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.trim",
      value: _vm.$v.form.email.$model,
      expression: "$v.form.email.$model",
      modifiers: {
        "trim": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "email",
      "required": ""
    },
    domProps: {
      "value": _vm.$v.form.email.$model
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.form.email, "$model", $event.target.value.trim());
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  }), !_vm.$v.form.email.email ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.invalid-email")) + " ")]) : _vm._e(), _vm.$v.form.email.$error && !_vm.$v.form.email.required ? _c('div', {
    staticClass: "form-error"
  }, [_vm._v(" " + _vm._s(_vm.$t("validation.email")) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group position-relative"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("username")))]), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")]), _c('input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "readonly": "",
      "id": "",
      "required": ""
    },
    domProps: {
      "value": _vm.user.username
    }
  })])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative text-left"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group position-relative text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_c('i', {
    staticClass: "mdi mdi-file-document"
  }), _vm._v(" " + _vm._s(_vm.$t("update")) + " "), _vm.isSubmit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])])])])])]), _c('b-modal', {
    ref: "modalPostcode",
    staticClass: "modal fade",
    attrs: {
      "title": _vm.$t('add-newpostcode'),
      "ok-title": _vm.$t('save'),
      "cancel-title": _vm.$t('cancel')
    },
    on: {
      "ok": _vm.storePostcode
    }
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("postcode")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.postcode,
      expression: "formPostcode.postcode"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "number"
    },
    domProps: {
      "value": _vm.formPostcode.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "postcode", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("city")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.city,
      expression: "formPostcode.city"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "city", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "col-md-6 form-group"
  }, [_c('label', [_vm._v(" " + _vm._s(_vm.$t("state")) + " "), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "uppercase",
      rawName: "v-uppercase"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.formPostcode.state,
      expression: "formPostcode.state"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.formPostcode.state
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.formPostcode, "state", $event.target.value);
      }
    }
  })])])])])], 1)])])])])])]), _c('Footer'), _c('a', {
    directives: [{
      name: "scroll-to",
      rawName: "v-scroll-to",
      value: '#topnav',
      expression: "'#topnav'"
    }],
    staticClass: "btn btn-icon btn-soft-primary back-to-top",
    attrs: {
      "href": "javascript: void(0);",
      "id": "back-to-top"
    }
  }, [_c('arrow-up-icon', {
    staticClass: "icons"
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }